<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl py-0 px-3>
          <v-flex xs2 sm1 pt-3 offset-sm11 offset-xs10 ref="actionBar">
              <div class="text-right">
                  <v-btn-toggle
                          color="primary"
                          dense
                          multiple
                  >
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                  <v-btn @click="createDialog = true">
                      <v-icon small dark v-on="on">ti ti-plus</v-icon>
                  </v-btn>
                  </template>
                  <span>{{ $t('message.createNew') }}</span>
                  </v-tooltip>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                          <v-btn
                              v-on="{...tooltip, ...menu}">
                              <v-icon small dark v-on>ti ti-export</v-icon>
                          </v-btn>
                      </template>
                      <span>{{ $t('message.export') }}</span>
                    </v-tooltip>
                  </template>
                      <v-list>
                          <v-list-item
                          @click="">
                              <v-list-item-title>{{ $t('message.asPdf') }}</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="">
                              <v-list-item-title>{{ $t('message.asExcel') }}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>
              </v-btn-toggle>
              </div>
          </v-flex>
          <v-flex xs12 sm12 pt-3 ref="searchBar">
              <v-form
                      ref="form"
                      lazy-validation>
                  <v-layout row class="dense-inputs">
                      <v-flex xs12 sm2>
                          <v-autocomplete
                                  :label="$t('message.country')"
                                  :items="countries"
                                  v-model="filters['country_id']"
                                  clearable
                                  solo
                                  dense
                                  background-color="white"
                                  hide-details
                                  item-value="id"
                                  item-text="name"
                                  multiple
                          >
                          </v-autocomplete>
                      </v-flex>
                      <v-flex xs12 sm2>
                          <v-text-field
                                  v-model="name"
                                  append-icon="search"
                                  :label="$t('message.shippingPort')"
                                  single-line
                                  hide-details
                                  background-color="yellow lighten-2"
                                  solo
                          ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm2>
                          <v-text-field
                                  v-model="unlocode"
                                  append-icon="search"
                                  :label="$t('message.unlcode')"
                                  single-line
                                  hide-details
                                  background-color="yellow lighten-2"
                                  solo
                          ></v-text-field>
                      </v-flex>
                  </v-layout>
              </v-form>
          </v-flex>
          <v-layout row wrap>
              <app-card
                      :fullBlock="true"
                      colClasses="xl12 lg12 md12 sm12 xs12"
                      pa0
              >
                  <v-data-table
                          :headers="headers"
                          :items="filteredItems"
                          :custom-filter="customFilter"
                          :search="search"
                          :footer-props="pagination"
                          item-key="id"
                          class="elevation-1 appic-table"
                          :key="tableKey"
                          :items-per-page="25"
                          dense
                          calculate-widths
                          fixed-header
                          :height="tableheight"
                  >

                      <template v-slot:item="props">
                          <tr>
                              <td @click="createDialog = true">{{ props.item.country }}</td>
                              <td @click="createDialog = true">{{ props.item.name }}</td>
                              <td @click="createDialog = true">{{ props.item.unlocode }}</td>
                              <td>
                                  <v-edit-dialog
                                          :return-value.sync="props.item.status"
                                          large
                                          persistent
                                          @save="save"
                                          @cancel="cancel"
                                          @open="open"
                                          @close="close"
                                  >
                                      <div class="text-center">
                                          <v-chip :color="getStatusColor(props.item.status)" dark x-small>{{ props.item.status }}</v-chip>
                                      </div>
                                      <template v-slot:input>
                                          <div class="mt-4 title">{{ $t('message.updateStatus') }}</div>
                                      </template>
                                      <template v-slot:input>
                                          <v-autocomplete
                                                  v-model="props.item.status"
                                                  :items="filters.statuses"
                                                  :placeholder="$t('message.status')"
                                                  label="Edit"
                                                  clearable
                                          ></v-autocomplete>
                                      </template>
                                  </v-edit-dialog>
                              </td>
                          </tr>
                      </template>
                      <template v-slot:no-data>
                          {{ $t('message.noData') }}
                      </template>
                  </v-data-table>
              </app-card>
          </v-layout>
        </v-container>
        <v-dialog
                v-model="createDialog"
                transition="dialog-bottom-transition"
                overlay=false
                scrollable
                max-width="600px"
                width="600px"
        >
            <v-card>
                <v-card-title>
                    <v-toolbar
                            color="success"
                            dark
                            flat
                            dense
                    >
                        <v-btn icon @click.native="createDialog = false" dark>
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ $t('message.createShippingPort') }}</v-toolbar-title>
                    </v-toolbar>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md class="dense-inputs">
                        <v-form
                                ref="form"
                                lazy-validation>
                            <v-layout row pt-3>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.continent') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-select
                                            v-model="shippingport.continents"
                                            solo
                                            dense
                                            required
                                            :rules="[v => !!v || '']"
                                            :items="filters.continent"
                                            item-text="name"
                                            item-value="id"
                                            :label="shippingport.continents ? '' : $t('message.required')"
                                            hide-details
                                    ></v-select>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.shippingPort') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingport.shippingPorts"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingport.shippingPort ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs6 md5 pl-5 py-1 class="red--text">{{ $t('message.country') }}*</v-flex>
                                <v-flex xs6 md7 py-1>
                                    <v-text-field
                                            v-model="shippingport.country_ide"
                                            solo
                                            required
                                            :rules="[v => !!v || '']"
                                            :label="shippingport.country_id ? '' : $t('message.required')"
                                            hide-details
                                    ></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error lighten-2" @click.stop="close">{{$t('message.close')}}</v-btn>
                    <v-btn color="primary lighten-2" @click.save="save">{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { api } from "../../../api";
    import {mapGetters} from "vuex";
    import {mapActions} from "vuex";
    export default {
        name: "ShippingPorts",
        data() {
            return {
                loader: true,
                search: '',
                unlocode: '',
                name: '',
                headers: [
                    {
                        text: "Country",
                        value: "country"
                    },
                    {
                        text: "Shipping Port",
                        value: "name",
                        filter: value => {
                          if (!this.name) return true
                          return value == (this.name)
                        }
                    },
                    {
                        text: "United Nations Code for Trade and Transport Locations",
                        value: "unlocode",
                        filter: value => {
                          if (!this.unlocode) return true
                          return value == (this.unlocode)
                        }
                    },
                    {
                        text: "Status",
                        value: "status"
                    },
                ],
                watch: {
                  params: {
                    handler() {
                      this.getDataFromApi().then(data => {
                        console.log("GETDATA");
                        this.items = data.items;
                      })
                    },
                    deep: true
                  }
                },
                items: [],
                pagination: {
                    page: 1,
                    itemsPerPageAllText: "$vuetify.dataFooter.itemsPerPageAll",
                    itemsPerPageOptions: [25, 40, 60, -1]
                },
                tableKey: 0,
                tableheight: "100vh",
                countries: [],
                statuses: [
                    {text: 'Active', value: 0},
                    {text: 'Suspended', value: 1}
                ],
                filters: {
                    continent: [],
                },
                createDialog: false,
                shippingport: {},
                snack: false,
                snackColor: '',
                snackText: '',
            }
        },
        computed: {
            filteredItems() {
              return this.items.filter(d => {
                return Object.keys(this.filters).every(f => {
                  return this.filters[f].length < 1 || this.filters[f].includes(d[f])
                })
              })
            },
            params(nv) {
              return {
                query: this.search
              };
            }
          },
        methods: {
            handleResize() {
                this.tableheight = window.innerHeight - (137 + this.$refs.breadcrumbBar.clientHeight + this.$refs.searchBar.clientHeight);
            },
            getTableData() {
                api
                    .get("appic/getshippingports")
                    .then(response => {
                        this.loader = false
                        this.items = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getCountries() {
                api
                    .get("appic/getcountries")
                    .then(response => {
                        this.loader = false
                        this.countries = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            getDataFromApi() {
              this.loading = true;
              return new Promise((resolve) => {
                let items = this.getItems();
                setTimeout(() => {
                  this.loading = false;
                  resolve({
                    items,
                  });
                });
              });
            },
            getItems() {
              return []
            },
            getStatusColor(status) {
                if (status == 'Active') return 'green'
                else return 'grey'
            },
            save () {
              if (this.$refs.form.validate()) {
                this.createDialog = false;
                this.$refs.form.reset();
              }
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Data saved'
            },
            cancel () {
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Canceled'
            },
            open () {
                this.snack = true
                this.snackColor = 'info'
                this.snackText = 'Dialog opened'
            },
            close () {
                this.createDialog = false;
                this.$refs.form.reset();
                console.log('Dialog closed')
            },
        },
        mounted (){
            this.getTableData();
            this.getCountries();
            this.handleResize()
            this.getDataFromApi().then(data => {
              this.iems = data.items;
            });
        },
        created() {
            window.addEventListener('resize', this.handleResize)
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style scoped>

</style>
